import {WOW} from 'wowjs';

export default {
    init() {

        function isIE() {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            var is_ie = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1;

            return is_ie;
        }

        /* Create an alert to show if the browser is IE or not */
        if (isIE()){
            var root = document.documentElement;
            root.className += ' ie11';
        }

        // JavaScript to be fired on all pages
        const wow = new WOW();
        wow.init();

        $('.application__anchor').click(function() {
            var id = $(this).attr('href');

            console.log(id);

            $('html, body').animate({
                scrollTop: $(id).offset().top-100,
            }, 500);
        });

        $(function() {
            $('.app__inner__summary').matchHeight();
        });

        $('.partner__slider__slick__right').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            dots: false,
            arrows: false,
            variableWidth: false,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.partner__slider__slick__left').slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            dots: false,
            arrows: false,
            variableWidth: false,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.app__outer').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            dots: false,
            arrows: false,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1199,
                        settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 991,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 575,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                },
            },
            ],
        });

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if (scroll > 100) {
                $('.header').addClass('header--fix');
            }
            else {
                $('.header').removeClass('header--fix');
            }
        });


        // // Jquery-match-height
        setTimeout(function() {
            $('.app__inner').matchHeight()
            $('.app__inner__title').matchHeight();
            $('.overview__block__title').matchHeight();
            $('.overview__block__summary').matchHeight();
            $('.partners__slider__group').matchHeight();
        }, 300);

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
